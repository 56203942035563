import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import YouTube from "react-youtube";
import TimeAgo from "react-timeago";
import { useParams } from 'react-router-dom';
import {
  getForumComments,
  replyCommentForm,
} from "../../../../redux/reducers/forumSlice";
import { socket } from "../../../../App"
const Annoucement = () => {

  const opts = {
    width: "100%",
    borderRadius: "2rem",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  const { title } = useParams()
  const videoReady = (event) => {
    event.target.pauseVideo();
  };
  const { user } = useSelector((state) => state.protection)
  const { loading, error, errMsg, forumComments, showReplyForm, curComment } =
    useSelector((state) => state.forum);
  const currentForum = useSelector((state) => state.forumList).forums.find((forum) => title === forum.forumTopic.toLowerCase())
  console.log(currentForum)
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [reply, setReply] = useState("")

  useEffect(() => {
    dispatch(getForumComments());

    // eslint-disable-next-line
  }, []);

  const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  function readableDate(date) {
    const fullDate = new Date(date)
    const currDate = fullDate.getDate()
    const currMonth = fullDate.getMonth()
    const currYear = fullDate.getFullYear()
    return `${currDate}-${currMonth}-${currYear}`
  }
  return (
    <div className="text-dark-gray">
      <h2 className="text-2xl lg:text-3xl mb-4">Topic: {currentForum?.forumTopic}</h2>
      <h2 className="text-2xl lg:text-3xl">
        Theme: {currentForum?.forumTheme}
      </h2>
      <div className="flex flex-col lg:flex-row lg:items-center justify-between py-4 border-b border-gray">
        <p className="font-bold mb-3 lg:mb-0">540 Posts</p>
        <p className="">
          <span className="font-bold">Last Post {""}</span>
          by Raja Muhammad on {readableDate(currentForum?.createDate)}
        </p>
      </div>
      <div className="">
        <div className="my-8 mx-auto w-full bg-very-light-gray mb-8 lg:mb-0">
          <YouTube
            className="rounded-md overflow-hidden"
            videoId="2g811Eo7K8U"
            opts={opts}
            onReady={videoReady}
          />
        </div>
        <div className="">
          <div className="mt-8 flex items-center justify-between">
            <div className="flex items-center">
              <div className="rounded-full border border-dark-gray w-20 h-20 overflow-hidden mr-2 flex">
                <img
                  src="https://yt3.ggpht.com/xGjOkcPW4OShTDzH9sFwNU0EfZbhL3fjgtEZ1AyUOKIiO4V1XOPXzGHd5Ewc1yWBdAuPG2YNCuI=s88-c-k-c0x00ffffff-no-rj"
                  alt="maisha digital youtube logo"
                  className="w-12 h-12 rounded-full m-auto"
                />
              </div>
              <div className="">
                <h3 className="font-bold text-2xl">Maisha Digital</h3>
                <p className="mt-1 flex items-center">
                  Moderator
                  <svg
                    className="ml-1"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.11398 0.333252H9.89398C12.154 0.333252 13.6673 1.91992 13.6673 4.27992V9.72725C13.6673 12.0799 12.154 13.6666 9.89398 13.6666H4.11398C1.85398 13.6666 0.333984 12.0799 0.333984 9.72725V4.27992C0.333984 1.91992 1.85398 0.333252 4.11398 0.333252ZM6.62065 8.99325L9.78732 5.82659C10.014 5.59992 10.014 5.23325 9.78732 4.99992C9.56065 4.77325 9.18732 4.77325 8.96065 4.99992L6.20732 7.75325L5.04065 6.58659C4.81398 6.35992 4.44065 6.35992 4.21398 6.58659C3.98732 6.81325 3.98732 7.17992 4.21398 7.41325L5.80065 8.99325C5.91398 9.10659 6.06065 9.15992 6.20732 9.15992C6.36065 9.15992 6.50732 9.10659 6.62065 8.99325Z"
                      fill="#9B1C28"
                    />
                  </svg>
                </p>
              </div>
            </div>
            <div className="flex-1 w-full max-w-[15rem] lg:max-w-xs lg:w-fit mt-4 lg:mt-0 flex items-center justify-between lg:ml-auto text-strong-red font-medium">
              <div className="fav-count flex items-center text-xs lg:text-base border border-strong-red rounded-md px-2 py-1 h-[34px]">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.9189 12.3201C15.6599 12.5711 15.5409 12.9341 15.5999 13.2901L16.4889 18.2101C16.5639 18.6271 16.3879 19.0491 16.0389 19.2901C15.6969 19.5401 15.2419 19.5701 14.8689 19.3701L10.4399 17.0601C10.2859 16.9781 10.1149 16.9341 9.93988 16.9291H9.66888C9.57488 16.9431 9.48288 16.9731 9.39888 17.0191L4.96888 19.3401C4.74988 19.4501 4.50188 19.4891 4.25888 19.4501C3.66688 19.3381 3.27188 18.7741 3.36888 18.1791L4.25888 13.2591C4.31788 12.9001 4.19888 12.5351 3.93988 12.2801L0.328876 8.78009C0.0268758 8.48709 -0.0781242 8.04709 0.0598758 7.65009C0.193876 7.25409 0.535876 6.96509 0.948876 6.90009L5.91888 6.17909C6.29688 6.14009 6.62888 5.91009 6.79888 5.57009L8.98888 1.08009C9.04088 0.980092 9.10788 0.888092 9.18888 0.810092L9.27888 0.740092C9.32588 0.688092 9.37988 0.645092 9.43988 0.610092L9.54888 0.570092L9.71888 0.500092H10.1399C10.5159 0.539092 10.8469 0.764092 11.0199 1.10009L13.2389 5.57009C13.3989 5.89709 13.7099 6.12409 14.0689 6.17909L19.0389 6.90009C19.4589 6.96009 19.8099 7.25009 19.9489 7.65009C20.0799 8.05109 19.9669 8.49109 19.6589 8.78009L15.9189 12.3201Z"
                    fill="#9B1C28"
                  />
                </svg>

                <span className="ml-2">150</span>
              </div>
              <div className="shared-count flex items-center text-xs lg:text-base border border-strong-red rounded-md px-2 py-1">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
                    stroke="#9B1C28"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.5 21.75C18.1569 21.75 19.5 20.4069 19.5 18.75C19.5 17.0931 18.1569 15.75 16.5 15.75C14.8431 15.75 13.5 17.0931 13.5 18.75C13.5 20.4069 14.8431 21.75 16.5 21.75Z"
                    stroke="#9B1C28"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.5 8.25C18.1569 8.25 19.5 6.90685 19.5 5.25C19.5 3.59315 18.1569 2.25 16.5 2.25C14.8431 2.25 13.5 3.59315 13.5 5.25C13.5 6.90685 14.8431 8.25 16.5 8.25Z"
                    stroke="#9B1C28"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.9777 6.87186C11.8469 8.24113 8.52148 10.3781 8.52148 10.3781"
                    stroke="#9B1C28"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.52148 13.6219L13.9777 17.1281"
                    stroke="#9B1C28"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <span className="ml-2">100</span>
              </div>
              <div className="community-count flex items-center text-xs lg:text-base border border-strong-red rounded-md px-2 py-1">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.592 15.2068C13.281 15.2068 16.434 15.7658 16.434 17.9988C16.434 20.2318 13.302 20.8068 9.592 20.8068C5.902 20.8068 2.75 20.2528 2.75 18.0188C2.75 15.7848 5.881 15.2068 9.592 15.2068Z"
                    stroke="#9B1C28"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.59108 12.0198C7.16908 12.0198 5.20508 10.0568 5.20508 7.63479C5.20508 5.21279 7.16908 3.24979 9.59108 3.24979C12.0121 3.24979 13.9761 5.21279 13.9761 7.63479C13.9851 10.0478 12.0351 12.0108 9.62208 12.0198H9.59108Z"
                    stroke="#9B1C28"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.4844 10.8816C18.0854 10.6566 19.3184 9.28259 19.3214 7.61959C19.3214 5.98059 18.1264 4.62059 16.5594 4.36359"
                    stroke="#9B1C28"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.5957 14.7322C20.1467 14.9632 21.2297 15.5072 21.2297 16.6272C21.2297 17.3982 20.7197 17.8982 19.8957 18.2112"
                    stroke="#9B1C28"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <span className="ml-2">49</span>
              </div>
            </div>
          </div>
          <div className="mt-8">
            <p className="">
              Maisha Digital is the digital marketing service and digital
              content arm of Maisha Online Africa. The digital team employs
              social media strategy, mass communication discipline and content
              designed to capture mindshare.
            </p>
            <p className="mt-2 text-justify">
              {currentForum.forumDescription}
            </p>
            <div className="flex flex-col lg:flex-row lg:items-center justify-between py-4">
              <p className="font-bold mb-3 lg:mb-0">540 Posts</p>
              <p className="">
                <span className="font-bold">Last Post </span>by Raja Muhammad on
                09-14-2022
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 flex lg:items-center">
        <div className="rounded-full border border-dark-gray w-12 h-12 overflow-hidden">
          <img
            src="https://yt3.ggpht.com/xGjOkcPW4OShTDzH9sFwNU0EfZbhL3fjgtEZ1AyUOKIiO4V1XOPXzGHd5Ewc1yWBdAuPG2YNCuI=s88-c-k-c0x00ffffff-no-rj"
            alt="maisha digital youtube logo"
            className="w-full h-full rounded-full"
          />
        </div>
        <form className="w-full pl-5 lg:pl-0 flex items-center justify-between flex-col lg:flex-row mt-6">
          <div className="relative w-full mb-2 lg:mb-0 py-3 lg:py-0 lg:mx-4">
            <input
              id="comment"
              name="comment"
              type="text"
              ref={inputRef}
              className="h-11 p-3 w-full border-b border-gray placeholder-gray focus:outline-none focus:border-strong-red"
              placeholder="Comment here"
            />
            <label htmlFor="comment" className="sr-only">
              Comment here
            </label>
          </div>
          <button
            // type="submit"
            onClick={(e) => {
              e.preventDefault();
              socket.emit("add_comment", {
                "forum_id": currentForum._id,
                "user_id": user._id,
                "message": inputRef.current.value,
                "room": currentForum.forumTopic
              })
              inputRef.current.value = ""
            }}
            className="h-11 w-full max-w-[10rem] ml-auto flex items-center justify-center bg-strong-red text-white rounded-md hover:bg-very-light-gray hover:text-dark-gray transtion-all cursor-pointer"
          >
            Comment
          </button>
        </form>
      </div>
      <>
        {!error ? (
          <>
            {loading ? (
              <p className="min-h-[30vh] text-center w-full font-semibold mt-10">
                Loading...
              </p>
            ) : (
              <div className="comments mt-10">
                {currentForum.commentId.map((comment, index) => (
                  <div
                    key={comment._id}
                    className="comment-container flex mb-10"
                  >
                    <div className="rounded-full w-12 h-12 overflow-hidden mr-4">
                      <img
                        src={
                          comment.userId.imageUrl
                        }
                        alt="author profile icon"
                        className="w-full"
                      />
                    </div>
                    <div className="flex-1 user-info">
                      <div className="flex text-dark-gray">
                        <h3 className="font-bold user-name">
                          {
                            comment.userId.fullname
                          }
                        </h3>
                        <span className="ml-2 text-gray">
                          <TimeAgo
                            date={
                              comment.postDate
                            }
                          />
                        </span>
                      </div>
                      <div className="comment-text">
                        {comment.content}{" "}
                        {""}
                        <button
                          onClick={(e) =>{
                            e.preventDefault();
                            dispatch(
                              replyCommentForm({
                                id: comment.id,
                                display: true,
                              })
                            ) 
                          }
                          }
                          className="border-b font-bold ml-1 hover:text-strong-red"
                        >
                          Reply
                        </button>
                      </div>
                      {showReplyForm && curComment === comment.id && (
                        // REPLY COMMENT FORM

                        <div className="mt-8 flex lg:items-center">
                          <div className="rounded-full border border-dark-gray w-9 lg:w-12 h-9 lg:h-12 overflow-hidden">
                            {/* <img
                                src="user's image display icon"
                                alt="user's display icon"
                                className="w-full h-full rounded-full"
                              /> */}
                          </div>
                          <form className="w-full flex-1 pl-5 lg:pl-0 flex items-center justify-between flex-row flex-wrap lg:flex-nowrap">
                            <div className="relative w-full mb-2 lg:mb-0 py-3 lg:py-0 lg:mx-4">
                              <input
                                id="reply"
                                name="reply"
                                type="text"
                                className="h-8 text-sm p-3 w-full border-b border-gray placeholder-gray focus:outline-none focus:border-strong-red"
                                placeholder="Reply here"
                                onChange={(e) => {setReply(e.target.value)}}
                              />
                              <label htmlFor="reply" className="sr-only">
                                Reply here
                              </label>
                            </div>
                            <button
                            onClick={(e) => {
                              e.preventDefault();
                              socket.emit("add_reply", {
                                "forum_id": currentForum._id,
                                "comment_id": comment._id,
                                "user_id": user._id,
                                "message": reply,
                                "room": currentForum.forumTopic
                              });
                              setReply("");
                              // dispatch(
                              //   replyCommentForm({
                              //     id: comment.id,
                              //     display: false,
                              //   })
                              // )
                            }}

                              className="h-8 text-sm w-full flex-1 lg:flex-none lg:max-w-[7rem] ml-auto mr-2 lg:mr-0 flex items-center justify-center bg-strong-red text-white rounded-md hover:bg-very-light-gray hover:text-dark-gray transtion-all"
                            >
                              Reply
                            </button>
                            <button 
                            onClick={() => {
                              dispatch(
                                replyCommentForm({
                                  id: comment.id,
                                  display: false,
                                })
                              )
                            }}  className="text-sm p-2 ml-3 flex items-center justify-center bg-very-light-gray text-gray rounded-md hover:bg-gray hover:text-very-light-gray transtion-all"
                            >
                              <ion-icon name="close" class="text-lg"></ion-icon>
                            </button>
                          </form>
                        </div>
                      )}
                      {comment.replies.length > 0 && (
                        <div className="comment-replies">
                          <div className="accordion" id="repliesAccordion">
                            <div className="accordion-item bg-white">
                              <div
                                className="accordion-header mb-0"
                                id={`heading1${comment._id}`}
                              >
                                <button
                                  className=" accordion-button !shadow-none !text-strong-red relative flex items-center w-full pt-4 transition focus:outline-none collapsed after:!bg-auto after:!ml-2 after:!w-3 after:!h-2"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#collapse1${comment._id}`}
                                  aria-expanded="true"
                                  aria-controls={`collapse1${comment._id}`}
                                >
                                  <h4 className="font-bold text-strong">
                                    {comment.replies.length} Replies
                                  </h4>
                                </button>
                              </div>
                              {comment.replies.map((reply, index) => (
                                <div
                                  key={index}
                                  id={`collapse1${reply._id}`}
                                  className="accordion-collapse"
                                  aria-labelledby={`heading1${reply._id}`}
                                  data-bs-parent="#repliesAccordion"
                                >
                                  <div className="accordion-body py-4 lg:px-5">
                                    <div className="comment-container flex">
                                      <div className="rounded-full w-12 h-12 overflow-hidden mr-4">
                                        <img
                                          src={
                                            reply.userId.imageUrl
                                          }
                                          alt="author profile icon"
                                          className="w-full"
                                        />{" "}
                                      </div>
                                      <div className="flex-1 user-info">
                                        <div className="flex items-center text-dark-gray">
                                          <h3 className="user-name font-bold flex-1" style={{color: "#000"}}>
                                            {reply.userId.fullname}
                                          </h3>
                                          <span className="ml-2 text-gray text-xs sm:text-sm lg:text-base" style={{color: "#000"}}>
                                            <TimeAgo
                                              date={reply.postDate}
                                            />
                                          </span>
                                        </div>
                                        <div className="comment-text" style={{color: "#000"}}>
                                          {reply.content}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        ) : (
          <>
            <p className=" mt-20 text-center text-very-dark-blue">
              {errMsg}. Reload page
            </p>
          </>
        )}
      </>
      <button
        type="button"
        data-mdb-ripple="true"
        data-mdb-ripple-color="light"
        id="btn-back-to-top"
        className="h-11 w-full max-w-[10rem] ml-auto flex items-center justify-center bg-dark-gray text-white rounded-md mt-4 lg:mb-12"
        onClick={scrollToTop}
      >
        Go back up
      </button>
    </div>
  );
};

export default Annoucement;
