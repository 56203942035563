import React from 'react'
import TimeAgo from "react-timeago"



const TestimonialItem = ({ testimonials }) => {

    function divideIntoTwoArrays(singleArray = [{}, {}]) {
        const arrayLength = singleArray.length
        const mid = Math.floor(arrayLength / 2)
        const firstArray = singleArray.slice(0, mid + 1)
        const secondArray = singleArray.slice(mid + 1, arrayLength)
        return [firstArray, secondArray]
    }

    return <div className='flex md:flex-row flex-col w-full'>
        <div className='flex flex-col'>
            {divideIntoTwoArrays(testimonials)[0].map((testimonial => <div key={testimonial._id} className='flex justify-between flex-row items-start w-full my-4'>
                <div className='rounded-full overflow-hidden basis-1/12 '>
                    <img src={testimonial.testifier.imageUrl} className='object-cover w-[50px] h-[50px] rounded-full' alt="User" />
                </div>
                <div className='mx-5 flex flex-col basis-11/12'>
                    <p className='font-bold'>{testimonial.testifier.fullname}<span className='font-thin mx-3'><TimeAgo date={testimonial.postDate} /></span></p>
                    <div className=''>
                        {testimonial.testimonyContent}
                        <div className='flex'>
                            {testimonial.imageUrls.map(imageUrl => <img src={imageUrl} className='w-[35px] h-[35px] object-cover mx-2 rounded' />)}
                        </div>
                    </div>
                </div>
            </div>))}
        </div>
        <div className='flex flex-col md:mx-10'>
            {divideIntoTwoArrays(testimonials)[1].map((testimonial => <div key={testimonial._id} className='flex justify-between flex-row items-start w-full my-4'>
                <div className='rounded-full overflow-hidden basis-1/12 '>
                    <img src={testimonial.testifier.imageUrl} className='object-cover w-[50px] h-[50px] rounded-full' alt="User" />
                </div>
                <div className='mx-5 flex flex-col basis-11/12'>
                    <p className='font-bold'>{testimonial.testifier.fullname}<span className='font-thin mx-3'><TimeAgo date={testimonial.postDate} /></span></p>
                    <div className=''>
                        {testimonial.testimonyContent}
                        <div className='flex'>
                            {testimonial.imageUrls.map(imageUrl => <img src={imageUrl} className='w-[35px] h-[35px] object-cover mx-2 rounded' />)}
                        </div>
                    </div>
                </div>
            </div>))}
        </div>
    </div>

}

export default TestimonialItem