import React, { useEffect } from 'react'
import { Navigate, Outlet } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import SignIn from '../pages/User/SignIn'
import UserForm from '../pages/User/UserForm'
import { getUser } from '../redux/reducers/protectionSlice'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Protection = (props) => {
    const dispatch = useDispatch()
    const state = useSelector(state => state.protection)

    useEffect(() => {
        dispatch(getUser())
    }, [])


    if (state.loading) {
        return <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={state.loading} >
            <CircularProgress color="inherit" />
        </Backdrop>

    }
    if (state.error === true && !state.user && !state.loading) {

        return <Navigate to="/auth/login" />
    }


    return (
        props.children
    )
}

export default Protection