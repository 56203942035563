import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css'
import App from './App';
import { store } from "./redux/store";
import { Provider } from 'react-redux'
import { GoogleOAuthProvider } from '@react-oauth/google';

const element = document.getElementById('root');
const root = createRoot(element)
root.render(
  <GoogleOAuthProvider clientId='422264668581-cvb807v5eb00uuq6if86g9n4n38p35ns.apps.googleusercontent.com'>
  <Provider store={store}>
    <App />
  </Provider>
  </GoogleOAuthProvider>

);

