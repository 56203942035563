import React from 'react'
import TimeAgo from "react-timeago"
import { Rating, Star } from '@smastrom/react-rating'

const Rate = ({ review }) => {
    return (
        <div className='flex justify-between max-w-[700px] my-4'>
            <div className='rounded-full overflow-hidden w-[50px] h-[50px] '>
                <img src={review.reveiwer.imageUrl} className='object-cover w-[50px] h-[50px] rounded-full' alt="User" />
            </div>
            <div className='w-full px-3'>
                <div className='flex  w-full'>
                    <p className='font-bold'>{review.reveiwer.fullname} <span className='font-thin '>
                    </span></p>
                    <TimeAgo className="ml-10" date={review.postDate} />
                </div>
                <div className='w-[100px] h-[30px]'>
                    <Rating value={review.rating} onChange={(e) => { }} readOnly className='w-[40px] h-[40px]'
                        itemStyles={{
                            itemShapes: Star,
                            activeFillColor: "black",
                            inactiveStrokeColor: "black",
                            itemStrokeWidth: 2,
                            activeStrokeColor: "black"
                        }} />
                </div>

                <div className='my-3'>
                    {review.reviewContent}
                </div>
            </div>
        </div>
    )
}

export default Rate

