import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "../components/layout/Layout";
import LayoutArts from "../components/layout/LayoutArts";
import VideoPage from "../pages/Media/VideoPage";
import HomeArts from "../pages/Arts/HomeArts";
import Home from "../pages/Home";
import Media from "../pages/Media/Media";
import SignIn from "../pages/User/SignIn";
import SignUp from "../pages/User/SignUp";
import UserForm from "../pages/User/UserForm";
import Wiki from "../pages/Wiki/Wiki";
import SearchResults from "../pages/Wiki/SearchResults";
import WikiPost from "../pages/Wiki/WikiPost";
import Forum from "../pages/Forum/Forum";
import Notices from "../pages/Forum/ForumPages/Notices";
import EducationalVideos from "../pages/Forum/ForumPages/EducationalVideos";
import EducationalLiterature from "../pages/Forum/ForumPages/EducationalLiterature";
import MaishaStore from "../pages/Forum/ForumPages/MaishaStore";
import Annoucement from "../pages/Forum/ForumPages/NoticesPages/Annoucement";
import NoticesHome from "../pages/Forum/ForumPages/NoticesPages/NoticesHome";
import Crowdfunding from "../pages/Crowdfunding/Crowdfunding";
import CrowdfundingPost from "../pages/Crowdfunding/CrowdfundingPost/CrowdfundingPost";
import Profile from "../pages/Profile/Profile";
import Ourworks from "../pages/Ourworks/Ourworks";
import Channels from "../pages/Channels/Channels";
import WorkPage from "../pages/Ourworks/WorkPage";
import UploadMedia from "../pages/Profile/UploadMedia";
import ChannelAbout from "../pages/Channels/ChannelAbout";
import MasterClass from "../pages/Channels/MasterClass";
import Protection from "./Protection";
import { Navigate } from "react-router-dom"
import Onboarding from "../pages/Onboarding/Onboarding";
import UserHomePage from "../pages/UserHome/UserHomePage";
import Shop from "../pages/Shop/Shop";
import ShopDetail from "../pages/Shop/ShopDetail";



// import Notices from "../pages/Forum/ForumPage/Notices";

function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

const MyRoutes = () => {


  useScrollToTop();
  return (
    <Routes>
      <Route path="/" element={
        <Onboarding />
      } />

      {/* Digital */}
      <Route path="/maisha-digital" element={
        <Protection><Layout /></Protection>
      }>
        <Route path="home" element={<Home />} />
        <Route path="dashboard" element={<UserHomePage />} />
        <Route path="shop" element={<Shop />} />

        <Route path="shop/:id" element={<ShopDetail />} />
        <Route path="media" element={<Media />} />
        <Route path="media/page/:number" element={<Media />} />
        <Route path="media/:id" element={<VideoPage />} />
        <Route path="profile" element={<Profile />} />
        <Route path="wiki" element={<Wiki />} />
        <Route path="wiki/search" element={<SearchResults />} />
        <Route path="wiki/:title" element={<WikiPost />} />
        <Route path="notices" element={<Notices />}></Route>
        <Route path="forum" element={<Forum />}>
          <Route path="" element={<Notices />}>
            <Route index path="" element={<NoticesHome />} />

            <Route path=":title">
              <Route index element={<Annoucement />} />
            </Route>
          </Route>
          <Route path="educational-videos" element={<EducationalVideos />} />
          <Route path="educational-literature" element={<EducationalLiterature />} />
          <Route path="maisha-store" element={<MaishaStore />} />
        </Route>

        <Route path="crowdfunding" element={<Crowdfunding />} />
        <Route path="crowdfunding/:title" element={<CrowdfundingPost />} />

        <Route path="our-works" element={<Ourworks />} />
        <Route path="our-works/:work" element={<WorkPage />} />
        {/* TO BE CORRECTED */}
        <Route path="about" element={<Profile />} />
        <Route path="about/page/:number" element={<Profile />} />
        <Route path="profile/upload" element={<UploadMedia />} />
        <Route path="*" element={<Navigate to="/" />}> </Route>
      </Route>
      {/* ARTS */}
      <Route path="/maisha-arts" element={
        <Protection><LayoutArts /></Protection>
      }>
        <Route path="home" element={<HomeArts />} />
        <Route path="dashboard" element={<UserHomePage />} />
        <Route path="shop" element={<Shop />} />
        <Route path="shop/:id" element={<ShopDetail />} />
        <Route path="media" element={<Media />} />
        <Route path="media/page/:number" element={<Media />} />
        <Route path="media/:id" element={<VideoPage />} />

        <Route path="wiki" element={<Wiki />} />
        <Route path="wiki/search" element={<SearchResults />} />
        <Route path="wiki/:title" element={<WikiPost />} />

        <Route path="forum" element={<Forum />}>
          <Route path="" element={<Notices />}>
            <Route index path="" element={<NoticesHome />} />

            <Route path=":title">
              <Route index element={<Annoucement />} />
            </Route>
          </Route>
          <Route path="educational-videos" element={<EducationalVideos />} />
          <Route path="educational-literature" element={<EducationalLiterature />} />
          <Route path="maisha-store" element={<MaishaStore />} />
        </Route>

        <Route path="channels" element={<Channels />} />
        <Route path="channels/:channel-about" element={<ChannelAbout />} />
        <Route path="channels/page/:number" element={<Media />} />
        <Route path="channels/masterclass" element={<Channels />} />
        <Route path="channels/masterclass/:masterclass" element={<MasterClass />} />

        <Route path="crowdfunding" element={<Crowdfunding />} />
        <Route path="crowdfunding/:title" element={<CrowdfundingPost />} />

        <Route path="about" element={<Profile />} />
        <Route path="about/page/:number" element={<Profile />} />
        <Route path="about/upload" element={<UploadMedia />} />
        <Route path="*" element={<Navigate to="/maisha-arts" />}> </Route>
      </Route>

      <Route path="/auth" element={<UserForm />}>
        <Route path="login" element={<SignIn />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="*" element={<Navigate to="/" />}> </Route>
      </Route>

    </Routes>
  );
};

export default MyRoutes;
