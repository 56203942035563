import React, { useEffect } from "react";
import Hero from "../components/Home/Hero";
import OnInstagram from "../components/Home/OnInstagram";
import RecommendedForYou from "../components/Home/RecommendedForYou";
import { useState } from "react";

const Home = () => {

  const [newMessage, setNewMessage] = useState(null);

  return (
    <>

      <Hero />
      <RecommendedForYou />
     {/* <OnInstagram /> */}
    </>
  );
};

export default Home;
