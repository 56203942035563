import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { RiArrowDropDownLine } from "react-icons/ri"
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';



export default function AccountToggle() {
    const authState = useSelector(state => state.protection);
    const [open, setOpen] = React.useState(false);
    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    function shortenWords(word, shorten = 5) {
        const lenght = word?.length
        if (!word || lenght < shorten) {
            return word
        }
        return word.substring(0, shorten) + "..."
    }
    const options = [
        {
            channelName: `${shortenWords(authState.user.fullname)} (Me)`,
            _id: 0,
        },
        ...authState.user.channelAccess,
        { channelName: "Log Out", _id: -1 }
    ];
    const handleClick = () => {
        console.info(`You clicked ${options[selectedIndex]}`);
    };


    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <React.Fragment>
            <ButtonGroup variant="text" color='error' ref={anchorRef} aria-label="split button">
                <Button onClick={handleClick}>{shortenWords(options[authState.accountSelect].channelName, 7)}</Button>
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <RiArrowDropDownLine />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow

                        {...TransitionProps}
                        style={{

                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper >
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" className='text-strong-red' autoFocusItem>
                                    {options.map((option, index) => {
                                        return (
                                            <MenuItem
                                                key={"option.key"}
                                                disabled={index === authState.accountSelect}
                                                selected={index === authState.accountSelect}
                                                onClick={(event) => handleMenuItemClick(event, index)}
                                            >
                                                {option.channelName}
                                            </MenuItem>
                                        );
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
}
