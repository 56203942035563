import { BrowserRouter } from "react-router-dom";
import MyRoutes from "./routes/MyRoutes";
import { io } from "socket.io-client";
import { useEffect, useState } from "react";
import { Snackbar } from "@mui/material";
import { wsBaseUrl } from "./config";

export const socket = io(`${wsBaseUrl}/api/v1/forum`)

export default function App() {
  const [flashMsg, setFlashMsg] = useState(null)
  const showFlash = (message) => {
    setFlashMsg(message);
    setTimeout(() => {
      setFlashMsg(null)
    }, 5000)
  }

  useEffect(() => {

    socket.on("new_message", (event) => {
      showFlash(event.message)

    })
    socket.on("new_comment", (event) => {
      showFlash(event.commentInfo.content);
    })

    socket.on("new_reply", (event) => {
      showFlash(event.replyInfo.content);
    })
  }, [socket])


  return (
    <div className="App font-nunito">

      <Snackbar
        open={flashMsg != null}
        autoHideDuration={6000}
        onClose={() => {
          setFlashMsg(null)
        }}
        message={"Message from Forum: " + flashMsg}
      />


      <BrowserRouter>

        <MyRoutes />
      </BrowserRouter>
    </div>
  );
}
