import React from 'react'
import { NavLink, Outlet } from "react-router-dom";
import Footer from './Footer'
import Header from "./Header";
import logo from "../../assets/logo/logo_digital.png";
import Hero from "../Home/Hero";
import RecommendedForYou from "../Home/RecommendedForYou";

const Layout = ({ children }) => {
  return (
    <>
      <Header>
        <NavLink to="/" className="logo ">
          <img src={logo} alt="maisha digital logo" />
        </NavLink>
      </Header>
      <main className='min-h-[60vh]'>
        <Outlet />
      </main>
      <Footer />
    </>
  )
}

export default Layout