import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signInAction, googleSignInAction, faceBookSignInAction } from "../../redux/reducers/authSlice";
import Alert from '@mui/material/Alert';
import LoadingButton from "@mui/lab/LoadingButton";
import { GoogleLogin } from '@react-oauth/google';
import FacebookLogin from "react-facebook-login";
import { baseUrl } from "../../config";
import Flash from "../../components/FlashMessage/FlashMessage";
import { getUser } from "../../redux/reducers/protectionSlice";
import axios from "axios";

const SignIn = () => {
  const emailRef = useRef("");
  const [open, setOpen] = useState(false);
  const passwordRef = useRef("");
  const dispatchFN = useDispatch();
  const state = useSelector((state) => state.auth);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (state.user !== null || !state.loading) {
  //     setOpen(true);
  //   }
  //   setTimeout(() => {
  //     setOpen(false)
  //     dispatchFN(getUser())
  //     if (state.user !== null || state.user !== undefined) { navigate("/", { replace: true }) }
  //   }, 3000);
  // }, [state]);


  useEffect(() => {
    if (state.user) {
        setOpen(true);
        setTimeout(() => {
            setOpen(false);
            dispatchFN(getUser());
            navigate("/", { replace: true });
        }, 3000);
    }
}, [state.user]);


  const handleGoogleLogin = async (credentialResponse) => {
    dispatchFN(googleSignInAction(credentialResponse));
  };

  const loginFN = () => {
    dispatchFN(signInAction({ email: emailRef.current.value, password: passwordRef.current.value }))
  }
  const submitForm = (event) => {
    event.preventDefault();
    loginFN();
  }
  const handleClose = () => {
    setOpen(false);
  }

  const responseFacebook = (response) => {
    if(response?.email !== undefined || response?.email !== null){
      dispatchFN(faceBookSignInAction({email: response?.email}));
    }else{
      alert("Facebook authentication not active at the moment")
    }
  };

  return (
    <>
      {(open && !state.initial) && <Alert onClose={handleClose} severity={state.error && state.user == null ? "error" : "success"} sx={{ width: '100%' }}>
        {state.error && state.user == null ? state.errorMsg : "Login Sucessful"}
      </Alert>}
      <h1 className="font-bold text-3xl lg:text-4xl">Sign in</h1>

      <form onSubmit={submitForm} className="mt-12 w-full max-w-lg mx-auto px-5">
        <div className="relative w-full  mb-2 py-3">
          <input
            id="email"
            name="email"
            type="email"
            className="h-14 p-3 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
            placeholder="Email"
            required
            ref={emailRef}
          />
          <label htmlFor="email" className="sr-only">
            Email
          </label>
        </div>
        <div className="relative w-full mb-12 py-3">
          <input
            id="password"
            name="password"
            type="password"
            className="h-14 p-3 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
            placeholder="Password"
            ref={passwordRef}
            required
          />
          <label htmlFor="password" className="sr-only">
            Password
          </label>
        </div>
        {state.loading && <LoadingButton loading variant="outlined" className="h-14 w-full flex items-center justify-center bg-strong-red text-white rounded-md hover:bg-very-light-gray hover:text-dark-gray transtion-all">
          Submit
        </LoadingButton>}
        {!state.loading && <button
          type="submit"

          className="h-14 w-full flex items-center justify-center bg-strong-red text-white rounded-md hover:bg-very-light-gray hover:text-dark-gray transtion-all"
        >
          Proceed
        </button>}
      </form>

      <div className="mt-6 flex flex-col items-center w-full max-w-lg">
        <h2 className="">Or</h2>
        <div className="mt-6 flex gap-x-4 flex-row w-full px-5">
          {/*<div className="fb-login bg-white w-full border border-gray rounded-md flex items-center justify-center p-5 flex-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M5.3191 14.5044L4.48366 17.6232L1.43016 17.6878C0.517613 15.9952 0 14.0587 0 12.0008C0 10.0109 0.483955 8.13429 1.3418 6.48193H1.34246L4.06093 6.98033L5.25179 9.68249C5.00254 10.4091 4.86669 11.1892 4.86669 12.0008C4.86679 12.8817 5.02636 13.7257 5.3191 14.5044Z"
                fill="#FBBB00"
              />
              <path
                d="M23.7904 9.75879C23.9282 10.4847 24 11.2344 24 12.0006C24 12.8598 23.9097 13.6978 23.7376 14.5062C23.1534 17.2571 21.627 19.6592 19.5124 21.359L19.5118 21.3583L16.0877 21.1836L15.6031 18.1584C17.0062 17.3356 18.1028 16.0478 18.6804 14.5062H12.2634V9.75879H18.774H23.7904Z"
                fill="#518EF8"
              />
              <path
                d="M19.5138 21.358L19.5145 21.3586C17.4579 23.0117 14.8455 24.0007 12.0017 24.0007C7.43161 24.0007 3.4583 21.4463 1.4314 17.6873L5.32033 14.5039C6.33376 17.2086 8.94287 19.134 12.0017 19.134C13.3164 19.134 14.5482 18.7785 15.6051 18.1581L19.5138 21.358Z"
                fill="#28B446"
              />
              <path
                d="M19.6593 2.76273L15.7717 5.94547C14.6778 5.26172 13.3848 4.86674 11.9995 4.86674C8.87142 4.86674 6.21351 6.88042 5.25085 9.6821L1.34148 6.48155H1.34082C3.33805 2.63086 7.36148 0 11.9995 0C14.9112 0 17.581 1.03719 19.6593 2.76273Z"
                fill="#F14336"
              />
            </svg>
            <p className="ml-3 text-sm sm:text-base">Facebook</p>
          </div>*/}
          {/* <div className="google-login bg-white w-full border border-gray rounded-md flex items-center justify-center p-5 flex-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clipPath="url(#clip0_161_6396)">
                <path
                  d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.38823 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9165 4.6875 14.6576 4.6875C15.9705 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.3399 7.875 13.875 8.80001 13.875 9.74899V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z"
                  fill="#1877F2"
                />
                <path
                  d="M16.6711 15.4688L17.2031 12H13.875V9.74899C13.875 8.80001 14.3399 7.875 15.8306 7.875H17.3438V4.92188C17.3438 4.92188 15.9705 4.6875 14.6576 4.6875C11.9165 4.6875 10.125 6.34875 10.125 9.35625V12H7.07812V15.4688H10.125V23.8542C10.7359 23.9501 11.3621 24 12 24C12.6379 24 13.2641 23.9501 13.875 23.8542V15.4688H16.6711Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_161_6396">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <p className="ml-3 text-sm sm:text-base">Google</p>
          </div>*/}
          <FacebookLogin
      appId="1070173991521454" // Replace with your Facebook App ID
      autoLoad={false}
      callback={responseFacebook}
      fields="name,email,picture"
      icon="fa-facebook"
    />
          <GoogleLogin
            className="sign"
            onSuccess={credentialResponse => {
              handleGoogleLogin(credentialResponse)
            }}
            onError={() => {
              console.log('Login Failed');
            }}
          />
        </div>
        <div className="mt-6">
          Don't have an account?{" "}
          <Link to="/auth/signup" className="border-b border-strong-red font-bold">
            Sign up
          </Link>
        </div>
      </div>
    </>
  );
};

export default SignIn;
