import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { crowdFundingThunk } from "../../redux/reducers/crowdFunding";

const Crowdfunding = () => {
  const state = useSelector(state => state.crowdFunding)
  const dispathFn = useDispatch()



  useEffect(() => {

    dispathFn(crowdFundingThunk())

  }, [])
  return (
    <div className="mt-16 px-5 lg:px-8 xl:px-4 mb-8 min-h-[50vh]">
      {/* <div className="posts"> */}
      <h1 className="sr-only">Crowdfunding</h1>
      <div className="items-start justify-center flex-wrap w-full mb-8 max-w-xl lg:max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:items-stretch">
        {

          state.fundings.length === 0 ? <> {new Array(4).map((e, index) =>
            <div className="h-[150px] w-[150px] animate-pulse"></div>)} </> :

            state.fundings.map((crowdfunding, index) => {
              return <article key={index} className="post xl:max-w-[18rem] md:mx-3 lg:mx-4 mb-8 relative group flex flex-col justify-between hover:scale-105 transition-all duration-300 ease-in-out shadow rounded p-2">
                <figure className="flex flex-col overflow-hidden">
                  <img
                    src={state.fundings[index].crowdFundingImageUrl}
                    alt="media thumbnail"
                    className=" object-cover rounded-lg mb-4 bg-gray min-h-[198px] max-h-[220px] object-contain lg:min-h-[216px]"
                  />
                </figure>

                <div className="flex-1 flex flex-col justify-between">
                  <Link to={`${state.fundings[index]._id}`} className="font-bold hover:text-gray mb-2">
                    {state.fundings[index].nameOfCampaign}
                  </Link>
                  <p className="text-gray text-sm mb-2 truncate">
                    {state.fundings[index].campaignDescription}
                  </p>
                  <div className="flex whitespace-pre-wrap mb-2">
                    <span className="font-bold">${state.fundings[index].crowdFundingRecieved
                    } raised </span>
                    by <span className="font-bold">{state.fundings[index].supporters} supporters</span>
                  </div>
                </div>
              </article>
            })
        }
      </div>
    </div>
  );
};

export default Crowdfunding;
