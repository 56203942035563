import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../config";

export const getUser = createAsyncThunk("getUser", async (state, action) => {
    if (localStorage.getItem("token")) {
        const response = await fetch(baseUrl + "/user/details",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`

                }
            }
        )
        const user = (await response.json())["user"];
        if (user) {
            return user
        }

        throw new Error("Unavailable")
    } else {
        action.rejectWithValue("Unavailable");
        throw new Error("Unavailable")

    }

})


const protectionSlice = createSlice({
    name: "protectionSlice",
    initialState: {
        user: null,
        loading: false,
        error: false,
        accountSelect: 0
    },
    reducers: {
        switchAccount(state, action) {
            state.accountSelect = action.payload
            localStorage.setItem("currSec", action.payload)
        }
    },
    extraReducers: builder => {
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.user = action.payload
            state.loading = false
            state.error = false
        })
        builder.addCase(getUser.pending, (state, action) => {
            state.loading = true
            state.user = null
            state.error = false
        })
        builder.addCase(getUser.rejected, (state, action) => {
            state.loading = false
            state.error = true
            state.user = null
        })
    }
})
export default protectionSlice.reducer;
export const { switchAccount } = protectionSlice.actions