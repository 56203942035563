import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../config";

export const getShopProduct = createAsyncThunk("getShopProductAsync", async (args, thunkAPI) => {
    try {
        const response = await axios.get(`${baseUrl}/channel/get-products`)
        return thunkAPI.fulfillWithValue(response.data.products)
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data.message)
    }
})
export const getSingleShopProduct = createAsyncThunk("getSingleShopProductAsync", async (args, thunkAPI) => {
    try {
        const response = await axios.get(`${baseUrl}/channel/get-products/${args.id}`)
        return thunkAPI.fulfillWithValue(response.data.product)
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data.message)
    }
})
export const submitReview = createAsyncThunk("submitReviewAsync", async (args, thunkAPI) => {
    try {
        const response = await axios.post(`${baseUrl}/channel/add-product-review`, {
            reviewContent: args.reviewContent,
            productId: args.productId,
            rating: args.rating
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        }
        )
        return thunkAPI.fulfillWithValue(response.data.reviews)
    } catch (err) {
        console.log(err)
        return thunkAPI.rejectWithValue(err.response.data.message)
    }
})
export const submitTestimonial = createAsyncThunk("submitTestimonialAsync", async (args, thunkAPI) => {
    console.log("Here")
    const formData = new FormData()
    formData.append("content", args.content)
    formData.append("productId", args.productId)
    console.log(args.imageFiles)
    for (let imageFile of args.imageFiles) {
        formData.append("testimony_img", imageFile)
    }


    try {

        const response = await axios.post(`${baseUrl}/channel/add-product-testimonial`, formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        }
        )
        return thunkAPI.fulfillWithValue(response.data.testimonials)
    } catch (err) {
        console.log(err)
        return thunkAPI.rejectWithValue(err.response.data.message)
    }
})

const shopSlice = createSlice({
    name: "ShopSlice",
    initialState: {
        products: [],
        loading: false,
        error: false,
        selectedProd: null,
        quantity: 1,
        imageSelected: 0,
        submitReviewLoading: false,
        submitReviewError: false,
        submitReviewSuccess: false
    },
    reducers: {
        increment(state, action) {
            state.quantity += 1
        },
        decrement(state, action) {
            if (state.quantity > 1) {
                state.quantity -= 1
            }
        },
        changeSelectedImage(state, action) {
            state.imageSelected = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(getShopProduct.fulfilled, (state, action) => {
            state.products = action.payload
            // console.log(state.products)
            state.loading = false
        })
        builder.addCase(getShopProduct.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getShopProduct.rejected, (state, action) => {
            state.loading = false
            state.error = true
        })
        builder.addCase(getSingleShopProduct.fulfilled, (state, action) => {
            state.selectedProd = action.payload
            // console.log(state.products)
            state.loading = false
        })
        builder.addCase(getSingleShopProduct.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getSingleShopProduct.rejected, (state, action) => {
            state.loading = false
            state.error = true
        })
        builder.addCase(submitReview.fulfilled, (state, action) => {
            state.submitReviewSuccess = true
            state.submitReviewLoading = false
            state.selectedProd.review = action.payload
        })
        builder.addCase(submitReview.pending, (state, action) => {
            state.submitReviewLoading = true
        })
        builder.addCase(submitReview.rejected, (state, action) => {
            state.submitReviewLoading = false
            state.submitReviewError = true
        })
        builder.addCase(submitTestimonial.fulfilled, (state, action) => {
            state.submitReviewSuccess = true
            state.submitReviewLoading = false
            state.selectedProd.testimonials = action.payload
        })
        builder.addCase(submitTestimonial.pending, (state, action) => {
            state.submitReviewLoading = true
        })
        builder.addCase(submitTestimonial.rejected, (state, action) => {
            state.submitReviewLoading = false
            state.submitReviewError = true
        })
    }
})
export default shopSlice.reducer;
export const { decrement, increment, changeSelectedImage } = shopSlice.actions