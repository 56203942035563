import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Rating, Star } from '@smastrom/react-rating'
import '@smastrom/react-rating/style.css'
import { useState } from 'react'
import TestimonialItem from './TestimonialItem'

import { getSingleShopProduct, submitReview, submitTestimonial } from '../../redux/reducers/ShopSlice'
import { useDispatch, useSelector } from 'react-redux'
import { PaystackButton } from 'react-paystack'
import { increment, decrement, changeSelectedImage } from '../../redux/reducers/ShopSlice'
import TimeAgo from "react-timeago"
import { Backdrop, CircularProgress } from '@mui/material'
import { AiOutlineClose } from "react-icons/ai"
import { useRef } from 'react'
import Rate from "./Rating"
import { VscDiffAdded } from "react-icons/vsc"



const ShopDetail = () => {
    const { id } = useParams()

    const [onReview, setOnReview] = useState(true)
    const [showSetReviewBackdrop, setShowSetReviewBackdrop] = useState(false)
    const [reviewRating, setReviewRating] = useState(0)
    const [selectedImages, setSelectedImages] = useState({ previews: [], files: [] })
    const reviewRef = useRef()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getSingleShopProduct({ id }))
    }, [])
    const { selectedProd, loading, error, quantity, imageSelected,
        submitReviewLoading, submitReviewError, submitReviewSuccess } = useSelector(state => state.shop)
    const { user } = useSelector(state => state.protection)
    const handleSucess = (response) => {
    }
    const handleClose = (response) => { }
    useEffect(() => {
        setShowSetReviewBackdrop(false)
        setReviewRating(0)
    }, [submitReviewError, submitReviewSuccess])
    return (
        <>
            <div className='mx-10 my-5'>
                <div className='flex'>
                    <div className='flex flex-col basis-1/12 h-[350px]'>
                        {!loading && selectedProd !== null ? selectedProd.productImageURLs.map((imageURL, index) => {
                            return (<div className='my-1 cursor-pointer' onClick={(_) => {
                                dispatch(changeSelectedImage(index))
                            }}>
                                <img src={imageURL} className='w-[80%] h-full object-contain' />
                            </div>)
                        }) : <div>Loading</div>}
                    </div>
                    {!loading && selectedProd !== null ? <div className='basis-6/12 h-[350px]'>
                        <img src={selectedProd.productImageURLs[imageSelected]} className='w-full h-full object-contain' />
                    </div> : <div className='basis-6/12 h-[350px] bg-gray animate-pulse'>

                    </div>}
                    {!loading && selectedProd !== null ? <div className='basis-5/12'>
                        <span className='flex flex-row justify-between my-3'>
                            <p className='text-2xl'>{selectedProd.productName}</p>
                            <p className='text-lg font-bold'>{selectedProd.productPrice} GHS </p>
                        </span>
                        <p className='mt-5 font-bold'>Description</p>
                        <span>{selectedProd.description}</span>
                        <p className='mt-5 font-bold'>Details</p>
                        <ol className='list-disc ml-5'>
                            {selectedProd.details.map((detail => <li>{detail}</li>))}

                        </ol>
                        <p className='mt-5'>Sold by: <span className='text-strong-red font-bold'>Bright Ackwerh </span></p>
                        <p className='mt-2'>In stock: 15 Items</p>
                        <div className='flex items-center'>
                            <Rating orientation='horizontal' className='my-2' style={{ maxWidth: 175 }} value={selectedProd.ratingAvg} readOnly itemStyles={{ itemShapes: Star, activeFillColor: "black", activeStrokeColor: "black", inactiveStrokeColor: "black", itemStrokeWidth: 2 }} />
                            <p className='mx-2'>({selectedProd.review.length})</p>
                        </div>
                        <div className='my-5'>
                            <p className='text-gray'>Quality</p>
                            <div className='flex mx-4 '>
                                <section className='flex justify-between border-2 rounded-md px-3 py-2 mr-3 basis-1/6'>
                                    <svg onClick={(_) => {
                                        dispatch(decrement())
                                    }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.9199 22.75C5.99992 22.75 1.16992 17.93 1.16992 12C1.16992 6.07 5.99992 1.25 11.9199 1.25C17.8399 1.25 22.6699 6.07 22.6699 12C22.6699 17.93 17.8499 22.75 11.9199 22.75ZM11.9199 2.75C6.81992 2.75 2.66992 6.9 2.66992 12C2.66992 17.1 6.81992 21.25 11.9199 21.25C17.0199 21.25 21.1699 17.1 21.1699 12C21.1699 6.9 17.0199 2.75 11.9199 2.75Z" fill="#A4A4A4" />
                                        <path d="M15.9199 12.75H7.91992C7.50992 12.75 7.16992 12.41 7.16992 12C7.16992 11.59 7.50992 11.25 7.91992 11.25H15.9199C16.3299 11.25 16.6699 11.59 16.6699 12C16.6699 12.41 16.3399 12.75 15.9199 12.75Z" fill="#A4A4A4" />
                                    </svg>
                                    <p className='mx-3'> {quantity}</p>

                                    <svg onClick={(_) => {
                                        dispatch(increment())
                                    }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#414142" />
                                        <path d="M16 12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z" fill="#414142" />
                                        <path d="M12 16.75C11.59 16.75 11.25 16.41 11.25 16V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V16C12.75 16.41 12.41 16.75 12 16.75Z" fill="#414142" />
                                    </svg>

                                </section>
                                <PaystackButton className='basis-5/6 w-full text-center bg-strong-red text-white py-2 font-bold rounded-md' amount={selectedProd.productPrice * quantity * 100} publicKey={process.env.REACT_APP_TEST_KEY} email={user.email} onSuccess={handleSucess} onClose={handleClose} currency="GHS">

                                    Buy this Item

                                </PaystackButton>
                            </div>
                        </div>

                    </div> : <div className='basis-5/12 bg-gray animate-pulse'>


                    </div>}
                </div>
                {/* Testimonial Section */}
                <div className='flex flex-row justify-between'>
                    <div className='flex'>
                        <p className={`${onReview ? 'font-extrabold' : 'font-extrabold text-gray'} text-xl mx-3 cursor-pointer`} onClick={(e) => {
                            setOnReview(true)
                        }}>Review for this item ({selectedProd?.review?.length})</p>
                        <p className={`${!onReview ? 'font-extrabold' : 'font-extrabold text-gray'} text-xl mx-3 cursor-pointer`} onClick={(e) => {
                            setOnReview(false)
                        }}>Testimonials ({selectedProd?.testimonials?.length})</p>
                    </div>
                    <p className='text-strong-red underline font-semibold cursor-pointer' onClick={(e) => {
                        setShowSetReviewBackdrop(state => !state)
                    }}>{onReview ? "Leave a Review" : "Leave a Testimonial"}</p>
                </div>
                {showSetReviewBackdrop && <Backdrop className='z-20' open={showSetReviewBackdrop} >
                    {onReview ? <div className='w-[416px] h-[348px] bg-white rounded-xl p-8'>
                        <div className='w-full flex justify-between mb-8'>
                            <p className='font-bold'>Please leave your review</p>
                            <AiOutlineClose className='cursor-pointer' onClick={(e) => {
                                e.preventDefault()
                                setShowSetReviewBackdrop(false)
                            }} />
                        </div>
                        <textarea ref={reviewRef} placeholder='Your response'
                            className='w-full rounded-xl h-[124px] resize-none p-4 focus:outline-none border '></textarea>
                        <div className='flex items-center my-3'>
                            <p>Rate this item:</p>
                            <div className='w-[150px] mx-5'>
                                <Rating value={reviewRating} onChange={(val) => {
                                    setReviewRating(val)
                                }} itemStyles={{ itemShapes: Star, activeFillColor: "black", activeStrokeColor: "black", inactiveStrokeColor: "black", itemStrokeWidth: 2 }} />
                            </div>

                        </div>
                        <div className='w-full h-[50px] bg-strong-red rounded-lg flex justify-center items-center text-white font-bold' onClick={(e) => {
                            if (!submitReviewLoading && reviewRef.current.value) {
                                dispatch(submitReview({
                                    reviewContent: reviewRef.current.value,
                                    productId: selectedProd._id,
                                    rating: reviewRating
                                }))
                            }
                        }}>

                            {!submitReviewLoading ? "Submit" : <CircularProgress />}
                        </div>
                        {/* This is for Testimonials */}
                    </div> : onReview === false ? <div className='w-[416px] h-[380px] bg-white rounded-xl p-8'>
                        <div className='w-full flex justify-between mb-8'>
                            <p className='font-bold'>Please add your testimonial</p>
                            <AiOutlineClose className='cursor-pointer' onClick={(e) => {
                                e.preventDefault()
                                setShowSetReviewBackdrop(false)
                            }} />
                        </div>
                        <textarea ref={reviewRef} placeholder='Your response'
                            className='w-full rounded-xl h-[124px] resize-none p-4 focus:outline-none border '></textarea>
                        <p className='text-gray'>Add Images</p>
                        <div className='flex items-center my-3'>
                            <div className='mx-5 flex'>
                                {/* Images Preview */}
                                <div className='flex'>
                                    {
                                        selectedImages.previews.map((value, index) => <img width={40} height={40} className='mx-2' key={index} src={value} />)
                                    }
                                </div>
                                {/* Add Image Icon */}
                                <div className='mx-2'>
                                    <input name='testimony_img' id='previewImg' type="file" accept='image/*' hidden onChange={(e) => {
                                        const reader = new FileReader()
                                        reader.readAsDataURL(e.target.files[0])
                                        reader.onloadend = (_) => {
                                            setSelectedImages(state => ({ files: [...state.files, e.target.files[0]], previews: [...selectedImages.previews, reader.result] }))
                                        }
                                    }} />
                                    <label for="previewImg">
                                        <div className='w-[40px] h-[40px] rounded-lg border flex justify-center items-center'>
                                            <VscDiffAdded size={20} />
                                        </div>
                                    </label>
                                </div>

                            </div>

                        </div>
                        <div className='w-full h-[50px] bg-strong-red rounded-lg flex justify-center items-center text-white font-bold cursor-pointer' onClick={(e) => {
                            e.preventDefault()
                            console.log(submitReviewLoading, reviewRef.current.value)
                            if (!submitReviewLoading && reviewRef.current.value) {

                                dispatch(submitTestimonial({
                                    content: reviewRef.current.value,
                                    productId: selectedProd._id,
                                    imageFiles: selectedImages.files
                                }))
                                setSelectedImages(state => ({ files: [], previews: [] }))
                            }
                        }}>

                            {!submitReviewLoading ? "Submit" : <CircularProgress />}
                        </div>
                    </div> : <div className='w-[416px] h-[348px] bg-white rounded-xl p-8'>
                        <div className='w-full flex justify-between mb-8'>
                            <p className='font-bold'>Please leave your review</p>
                            <AiOutlineClose className='cursor-pointer' onClick={(e) => {
                                e.preventDefault()
                                setShowSetReviewBackdrop(false)
                            }} />
                        </div>
                        <textarea ref={reviewRef} placeholder='Your response'
                            className='w-full rounded-xl h-[124px] resize-none p-4 focus:outline-none border '></textarea>
                        <div className='flex items-center my-3'>
                            <p>Rate this item:</p>
                            <div className='w-[150px] mx-5'>
                                <Rating value={reviewRating} onChange={(val) => {
                                    setReviewRating(val)
                                }} itemStyles={{ itemShapes: Star, activeFillColor: "black", activeStrokeColor: "black", inactiveStrokeColor: "black", itemStrokeWidth: 2 }} />
                            </div>

                        </div>
                        <div className='w-full h-[50px] bg-strong-red rounded-lg flex justify-center items-center text-white font-bold' onClick={(e) => {
                            if (!submitReviewLoading && reviewRef.current.value) {
                                dispatch(submitReview({
                                    reviewContent: reviewRef.current.value,
                                    productId: selectedProd._id,
                                    rating: reviewRating
                                }))
                            }
                        }}>

                            {!submitReviewLoading ? "Submit" : <CircularProgress />}
                        </div>
                    </div>}
                </Backdrop>}
                {onReview && selectedProd ? <div className='my-4'>
                    {selectedProd.review.map((review, index) => <Rate review={review} />)}
                </div> : <div className=''>
                    {selectedProd && <TestimonialItem testimonials={selectedProd.testimonials} />}
                </div>}
                <div className='my-5'>
                    <p className='text-xl'>Other Items from this Seller</p>
                    <hr className='text-very-light-gray border rounded' />
                </div>
                <div className='flex py-5'>

                    {Array(3).fill().map((e, index) => (
                        // <ShopItem index={index} />
                        <div></div>
                    ))}
                </div>
            </div>
        </>

    )
}

export default ShopDetail