import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import logo from "../../assets/logo/logo_arts.svg";

const LayoutArts = ({ children }) => {
  return (
    <>
      <Header>
        <NavLink to="/maisha-arts" className="logo ">
          <img src={logo} alt="maisha arts logo" />
        </NavLink>
      </Header>
      <main className="min-h-[60vh]">
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default LayoutArts;
