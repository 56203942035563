import React from 'react'
import Pagination from '@mui/material/Pagination';
import ShopItem from './ShopItem';
import { useDispatch, useSelector } from 'react-redux';
import { getShopProduct } from "../../redux/reducers/ShopSlice"
import { useEffect } from 'react';

const Shop = () => {
    const dispatch = useDispatch()
    const { products, loading, error } = useSelector(state => state.shop)

    useEffect(() => {
        dispatch(getShopProduct())
    }, [])
    return (

        <div className="flex justify-center items-center min-h-screen flex-col my-10">


            {loading ? <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10 my-5">
                {Array(20).fill().map((_, index) => (
                    <div key={index} whileHover={{ scale: 1.05 }} onClick={(e) => {

                    }} transition={{
                        duration: 1
                    }} className="bg-white p-4 rounded-md w-64 h-64 flex flex-col items-center cursor-pointer bg-gray animate-pulse">

                    </div>
                ))}
            </div> : <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10 my-5">
                {products.map((product, index) => (
                    // <div></div>
                    <ShopItem product={product} />
                ))}
            </div>}
            <div className='flex items-center mt-10 justify-center'>
                <p className='mx-5 font-bold'>Page</p>
                <Pagination count={Math.ceil(products.length / 20)} />
            </div>

            <div className='flex justify-center items-start min-h-screen flex-col my-10'>
                <p className='font-bold text-left'>Recently Veiwed</p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10 my-5">
                    {[].map((product, index) => (
                        <ShopItem product={product} />
                        // <div></div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Shop