import React from 'react'
import dummyImage from "../../assets/shop/dummyItem.png"
import { motion } from 'framer-motion'
import { useNavigate, useLocation } from 'react-router-dom'

const ShopItem = ({ product }) => {
    const navigate = useNavigate()
    const location = useLocation()
    console.log(product)
    return (
        <motion.div key={product._id} whileHover={{ scale: 1.05 }} onClick={(e) => {
            if (location.pathname.includes("maisha-digital")) {
                navigate(`/maisha-digital/shop/${product._id}`)
            } else {
                navigate(`/maisha-arts/shop/${product._id}`)
            }

        }} transition={{
            duration: 1
        }} className="bg-white p-4 rounded-md w-64 h-64 flex flex-col items-center cursor-pointer">
            <div className='rounded-xl overflow-clip w-60 h-60'>
                <img className='object-cover' src={product.productImageURLs[0]} />
            </div>

            <p className='font-bold'>{product.productName}</p>
            <p>{product.productPrice} GHS</p>
        </motion.div>
    )
}

export default ShopItem