// import React, { useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { fecthRecommendedForYou } from "../../redux/reducers/recommendedSlice";
// import ReactTimeago from "react-timeago";
// import { Link } from "react-router-dom";

// const RecommendedForYou = () => {
//   const { loading, error, medias } = useSelector(state => state.recommended)
//   console.log(medias)
//   const dispatch = useDispatch()
//   useEffect(() => {
//     dispatch(fecthRecommendedForYou())
//   }, [])
//   return (
//     <section className="max-w-xl mx-auto lg:max-w-7xl">
//       <h2 className="text-lg text-center mb-8">Recommended for you</h2>
//       <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-evenly mb-8 max-w-6xl mx-auto px-5">
//         {loading && Array(4).map((dummy) => {
//           <figure className="bg-strong-red flex flex-col h-[155px] xl:h-[180px] w-[300px] xl:w-[336px] object-cover rounded-lg mb-8 lg:mb-0 animate-pulse">

//             <figcaption className="flex flex-col text-center">
//               <h3 className="w-[100px animate-pulse"></h3>
//               <h3 className="w-[100px animate-pulse"></h3>

//               <h3 className="w-[100px animate-pulse"></h3>
//             </figcaption>
//           </figure>
//         })}


//         {!loading && !error && medias.map((media) =>

//           <Link to={`/media/${media._id}`}>
//             <figure className="flex flex-col overflow-hidden mb-8 lg:mb-0">
//               <img
//                 src={media.videoThumbnailUrl}
//                 alt="plantain chips 1.3"
//                 className="h-[155px] xl:h-[180px] w-[300px] xl:w-[336px] object-cover rounded-lg mb-4"
//               />
//               <figcaption className="flex flex-col text-center">
//                 <h3 className="text-base font-bold mb-2"> {media.titleOfContent}</h3>
//                 <p className="mb-2">
//                   <ReactTimeago date={media.postDate} />

//                 </p>
//                 <p className="mb-2">
//                   by{" "}
//                   <span className="text-strong-red font-bold">{media.channelId?.channelName}</span>
//                 </p>
//               </figcaption>
//             </figure>
//           </Link>
//         )}
//       </div>
//     </section>
//   );
// };

// export default RecommendedForYou;



import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fecthRecommendedForYou } from "../../redux/reducers/recommendedSlice";
import ReactTimeago from "react-timeago";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

// Install Swiper modules
// SwiperCore.use([Navigation, Pagination, Autoplay]);

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

const RecommendedForYou = () => {
  const { loading, error, medias } = useSelector((state) => state.recommended);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fecthRecommendedForYou());
  }, [dispatch]);

  return (
    <section className="max-w-xl mx-auto lg:max-w-7xl">
      <h2 className="text-lg text-center mb-8">Recommended for you</h2>
      <Swiper
      modules={[Navigation, Pagination, Autoplay]}
        slidesPerView={1}
        spaceBetween={20}
      //  pagination={{ clickable: true }}
        autoplay={{ delay: 3000 }}
        breakpoints={{
          640: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 3 },
        }}
        className="mb-8 max-w-6xl mx-auto px-5"
      >
        {loading
          ? Array(4).fill().map((_, idx) => (
              <SwiperSlide key={idx}>
                <figure className="bg-strong-red flex flex-col h-[155px] xl:h-[180px] w-[300px] xl:w-[336px] object-cover rounded-lg animate-pulse mb-8 lg:mb-0" />
              </SwiperSlide>
            ))
          : !error &&
            medias.map((media) => (
              <SwiperSlide key={media._id}>
                <Link to={`/media/${media._id}`}>
                  <figure className="flex flex-col overflow-hidden mb-8 lg:mb-0 transition-transform duration-300 ease-out hover:scale-105">
                    <img
                      src={media.videoThumbnailUrl}
                      alt={media.titleOfContent}
                      className="h-[155px] xl:h-[180px] w-[300px] xl:w-[336px] object-cover rounded-lg mb-4"
                    />
                    <figcaption className="flex flex-col text-center">
                      <h3 className="text-base font-bold mb-2">{media.titleOfContent}</h3>
                      <p className="mb-2">
                        <ReactTimeago date={media.postDate} />
                      </p>
                      <p className="mb-2">
                        by{" "}
                        <span className="text-strong-red font-bold">
                          {media.channelId?.channelName}
                        </span>
                      </p>
                    </figcaption>
                  </figure>
                </Link>
              </SwiperSlide>
            ))}
      </Swiper>
    </section>
  );
};

export default RecommendedForYou;
